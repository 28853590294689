define("discourse/plugins/discourse-jira/discourse/components/post-menu/jira-menu-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/dropdown-menu", "discourse-i18n", "float-kit/components/d-menu", "discourse/plugins/discourse-jira/discourse/components/modal/attach", "discourse/plugins/discourse-jira/discourse/components/modal/create", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dropdownMenu, _discourseI18n, _dMenu, _attach, _create, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class JiraMenuButton extends _component.default {
    static shouldRender(args) {
      return !args.post.jira_issue;
    }
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    attachIssue() {
      this.modal.show(_attach.default, {
        model: this.args.post
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "attachIssue", [_object.action]))();
    createIssue() {
      this.modal.show(_create.default, {
        model: this.args.post
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "createIssue", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DMenu
          class="post-action-menu__jira-menu jira-menu"
          ...attributes
          @autofocus={{true}}
          @identifier="post-jira-menu"
          @icon="fab-jira"
          @modalForMobile={{true}}
          @title={{i18n "discourse_jira.menu.title"}}
        >
          <DropdownMenu as |dropdown|>
            <dropdown.item>
              <DButton
                class="post-action-menu__jira-create-issue create-issue btn-transparent"
                @action={{this.createIssue}}
                @icon="plus"
                @label="discourse_jira.menu.create_issue"
              />
            </dropdown.item>
            <dropdown.item>
              <DButton
                class="post-action-menu__jira-attach-issue attach-issue btn-transparent"
                @action={{this.attachIssue}}
                @icon="paperclip"
                @label="discourse_jira.menu.attach_issue"
              />
            </dropdown.item>
          </DropdownMenu>
        </DMenu>
      
    */
    {
      "id": "7KgHLPOK",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"post-action-menu__jira-menu jira-menu\"],[17,1]],[[\"@autofocus\",\"@identifier\",\"@icon\",\"@modalForMobile\",\"@title\"],[true,\"post-jira-menu\",\"fab-jira\",true,[28,[32,1],[\"discourse_jira.menu.title\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[32,3],[[24,0,\"post-action-menu__jira-create-issue create-issue btn-transparent\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"createIssue\"]],\"plus\",\"discourse_jira.menu.create_issue\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[32,3],[[24,0,\"post-action-menu__jira-attach-issue attach-issue btn-transparent\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"attachIssue\"]],\"paperclip\",\"discourse_jira.menu.attach_issue\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"dropdown\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-jira/discourse/components/post-menu/jira-menu-button.js",
      "scope": () => [_dMenu.default, _discourseI18n.i18n, _dropdownMenu.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = JiraMenuButton;
});